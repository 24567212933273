/* Google API Font */
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */

/* Local Font */
/* @font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./fonts/MyFont.woff) format('woff');
}  */

/* NPM Font */
/* npm install @fontsource/roboto */
/* Import .css in index.js */

::-webkit-scrollbar {
    background-color: #202324;
    color: #aba499;
}

::-webkit-scrollbar-corner {
    background-color: #181a1b;
}

::-webkit-scrollbar-thumb {
    background-color: #454a4d;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

:root{
    scrollbar-color: #454a4d #181a1b;
}